import debounce from "lodash-es/debounce"
import type { CookieRef } from "nuxt/app"
import type { LatLngLiteral } from "leaflet"

export interface DashboardMapView {
  /** Prevents restoring the initial values before fitBounds (or similar) is called */
  initialized: boolean
  coordinates: { lng: number; lat: number }
  zoom: number
  selectedFeatures?: Array<string>
}

export interface SamMapLayer {
  enabled: CookieRef<boolean>
  layer: Ref<string>
  mapIndex: Ref<number>
  legendUrl: Ref<string>
}

export interface MapFeatureData {
  layerKey: string
  features: Array<{ properties: Record<string, unknown> }>
}

export const geoPointToLatLng = (geoPoint: GeoPoint): LatLngAlt =>
  geoPoint.coordinates[2]
    ? {
        lng: geoPoint.coordinates[0],
        lat: geoPoint.coordinates[1],
        alt: geoPoint.coordinates[2],
      }
    : {
        lng: geoPoint.coordinates[0],
        lat: geoPoint.coordinates[1],
      }

export const latLngToGeoPoint = (latLngAlt: LatLngAlt): GeoPoint => ({
  type: "Point",
  coordinates: latLngAlt.alt
    ? // prettier-ignore
      [
      parseFloat(latLngAlt.lng.toFixed(7)),
      parseFloat(latLngAlt.lat.toFixed(7)),
      latLngAlt.alt]
    : [parseFloat(latLngAlt.lng.toFixed(7)), parseFloat(latLngAlt.lat.toFixed(7))],
})

export const assetRelationToPath = (relation: PopulatedAssetRelation) => [
  relation.asset1.location?.gps ? geoPointToLatLng(relation.asset1.location?.gps) : defaultCoords,
  relation.asset2.location?.gps ? geoPointToLatLng(relation.asset2.location?.gps) : defaultCoords,
]

export const pdokLayers = [
  "BeheerGebied",
  "BeheerPut",
  "BeheerLeiding",
  "BeheerPomp",
  "BeheerLozing",
  "BeheerBouwwerk",
  "AansluitingLeiding",
  "AansluitingPunt",
]

export interface IconSettings {
  color?: string
  label?: string
  highlighted?: boolean
  showBadge?: boolean
  size?: MarkerSize
}

const markerSizeDimensions = {
  [MarkerSize.SMALL]: { width: 30, height: 34 },
  [MarkerSize.MEDIUM]: { width: 36, height: 40 },
  [MarkerSize.LARGE]: { width: 44, height: 48 },
}

export const svgAssetUrl = (label: string, color = "grey", strokeWidth = 1, strokeColor = "#fff") =>
  `/markers/asset?label=${encodeURIComponent(label)}${color ? "&color=" + encodeURIComponent(color) : ""}&strokeWidth=${strokeWidth}&strokeColor=${encodeURIComponent(strokeColor)}`

export const svgTicketUrl = (type: TicketType, strokeWidth = 1, strokeColor = "#fff") =>
  `/markers/ticket?type=${type}&strokeWidth=${strokeWidth}&strokeColor=${encodeURIComponent(strokeColor)}`

export const generateMarkerIcon = (options: IconSettings) => {
  const { color, label, highlighted, showBadge, size } = options

  const strokeWidth = highlighted ? 1.5 : 1
  const strokeColor = highlighted ? "%23009cd1" : "%23ffffff"

  const generateBadge = (color?: string) =>
    `<circle cx="14" cy="1" r="2.5" stroke="${strokeColor}" stroke-width="${highlighted ? 1 : 0.7}" fill="${
      color ? encodeURIComponent(color) : "grey"
    }" />`

  const sizeDimensions = markerSizeDimensions[size ?? MarkerSize.MEDIUM]

  return {
    url: `data:image/svg+xml;utf-8, \
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="-1 -1 18 20"> \
        <style> text { fill: white; font: 400 7px Roboto, Arial, sans-serif; } </style> \
        <path d="m 3 0 l 10 0 c 2 0 3 1 3 3 l 0 10 c 0 2 -1 3 -3 3 l -1 0 l -4 2 l -4 -2 l -1 0 c -2 0 -3 -1 -3 -3 l 0 -10 c 0 -2 1 -3 3 -3 Z" stroke="${strokeColor}" stroke-width="${strokeWidth}" fill="${
          color ? encodeURIComponent(color) : "grey"
        }"/> \
        <text x="46%" y="46%" dominant-baseline="middle" text-anchor="middle" fill="white" font-size="12px">${label ?? "?"}</text> \
        ${showBadge ? generateBadge("#C10015") : ""} \
      </svg>`,
    scaledSize: sizeDimensions,
    anchor: { x: sizeDimensions.width / 2, y: sizeDimensions.height },
  }
}

export const navigateToDestination = (destination: { lat: number; lng: number }, currentDestination?: { lat: number; lng: number }) => {
  const isApple = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)

  if (isApple) {
    return `maps://maps.apple.com/?daddr=${destination.lat},${destination.lng}${
      currentDestination ? `&saddr=${currentDestination.lat},${currentDestination.lng}` : ""
    }`
  }

  return `https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${destination.lat},${destination.lng}${
    currentDestination ? `&origin=${currentDestination.lat},${currentDestination.lng}` : ""
  }`
}

export const duoppBaseUrl = "https://www.viewer-duopp.nl/cgi-bin/mapserv?map=/home/gisarts/apps/cook/duopp/map/authenticatie"
export const pdokBaseUrl = "https://service.pdok.nl/rioned/beheerstedelijkwater/wms/v1_0"

export const generateCenter = (coordinates: Array<[lng: number, lat: number]>) => {
  if (!coordinates.length) {
    return defaultCoords
  }

  const [lng, lat] = coordinates.reduce((acc, curr) => [acc[0] + curr[0], acc[1] + curr[1]])
  return {
    lng: lng / coordinates.length,
    lat: lat / coordinates.length,
  }
}

export const generateLayerOptions = (
  mapProjection: google.maps.Projection | undefined,
  layerKey: string,
  duoPPKey?: string,
): google.maps.ImageMapTypeOptions => {
  const TILE_SIZE = 256

  return {
    getTileUrl: function (coord: google.maps.Point, zoom: number) {
      const boundBox = tileCoordsToBBox(mapProjection, coord, zoom, TILE_SIZE, TILE_SIZE)

      const query = new URLSearchParams({
        SERVICE: "WMS",
        VERSION: duoPPKey ? "1.1.1" : "1.3.0",
        REQUEST: "GetMap",
        FORMAT: "image/png",
        TRANSPARENT: "true",
        STYLES: "",
        WIDTH: TILE_SIZE.toString(),
        HEIGHT: TILE_SIZE.toString(),
        LAYERS: layerKey,
        BBOX: duoPPKey
          ? `${boundBox[0]},${boundBox[1]},${boundBox[2]},${boundBox[3]}`
          : `${boundBox[1]},${boundBox[0]},${boundBox[3]},${boundBox[2]}`,
      })
      query.append(duoPPKey ? "SRS" : "CRS", "EPSG:4326")

      if (duoPPKey) {
        return `${duoppBaseUrl}/${duoPPKey}&${query}`
      } else {
        return `${pdokBaseUrl}?${query}`
      }
    },
    tileSize: new google.maps.Size(TILE_SIZE, TILE_SIZE),
    name: layerKey,
  }
}

export const generateLayerLegendUrl = (layerKey: string, duoPPKey?: string, layerName?: string) => {
  if (duoPPKey) {
    const query = new URLSearchParams({
      SERVICE: "WMS",
      VERSION: "1.3.0",
      REQUEST: "GetLegendGraphic",
      FORMAT: "image/png",
      SLD_VERSION: "1.1.0",
      LAYER: layerKey,
    })

    return `${duoppBaseUrl}/${duoPPKey}&${query}`
  }

  return `${pdokBaseUrl}/legend/${layerKey}/GWSW_${layerName}.png`
}

export const generateLayerCapabilitiesUrl = (layerKey: string): string => {
  const query = new URLSearchParams({
    SERVICE: "WMS",
    VERSION: "1.3.0",
    REQUEST: "GetCapabilities",
  })
  return `https://mapserver.viewer-duopp.nl/?map=/etc/mapserver/${layerKey}&${query}`
}

export const generateLayerFeaturesUrl = (scaleFactor: number, pointOfInterest: LatLngLiteral, layerKey: string): string => {
  const clickArea = 600
  const magicNumber = 50
  const magicSizeNumber = 101

  const xs = (pointOfInterest.lat * scaleFactor - clickArea / 2) / scaleFactor
  const xm = (pointOfInterest.lat * scaleFactor + clickArea / 2) / scaleFactor
  const ys = (pointOfInterest.lng * scaleFactor - clickArea / 2) / scaleFactor
  const ym = (pointOfInterest.lng * scaleFactor + clickArea / 2) / scaleFactor

  // Only allow for one layer per call. WMS returns json for one layer, and XML for the other if you do multiple at a time.
  const query = new URLSearchParams({
    SERVICE: "WMS",
    VERSION: "1.3.0",
    REQUEST: "GetFeatureInfo",
    QUERY_LAYERS: layerKey,
    LAYERS: layerKey,
    INFO_FORMAT: "application/json",
    I: magicNumber.toString(),
    J: magicNumber.toString(),
    WIDTH: magicSizeNumber.toString(),
    HEIGHT: magicSizeNumber.toString(),
    CRS: "EPSG:4326",
    BBOX: `${xs},${ys},${xm},${ym}`,
  })

  return `${pdokBaseUrl}?${query}`
}

export const getLayerFeatures = async (
  scaleFactor: number,
  pointOfInterest: LatLngLiteral,
  layerKeys: Array<string>,
): Promise<Array<MapFeatureData>> => {
  const generatedUrls = layerKeys.map((key) => {
    return {
      url: generateLayerFeaturesUrl(scaleFactor, pointOfInterest, key),
      layerKey: key,
    }
  })
  const data = await Promise.all(
    generatedUrls.map(async (generatedUrl) => {
      try {
        const response = await fetch(generatedUrl.url)
        const body: MapFeatureData = { layerKey: generatedUrl.layerKey, ...(await response.json()) }

        if (!body.features.length) {
          return null
        }

        return body
      } catch {
        return null
      }
    }),
  )

  return data.filter((feature): feature is MapFeatureData => !!feature)
}

export const tileCoordsToBBox = (
  mapProjection: google.maps.Projection | undefined,
  coord: google.maps.Point,
  zoom: number,
  tileWidth: number,
  tileHeight: number,
) => {
  // scale is because the number of tiles shown at each zoom level double.
  const scale = Math.pow(2, zoom)

  // A point is created for the north-east and south-west corners, calculated
  // by taking the tile coord and multiplying it by the tile's width and the map's scale.
  const ne = mapProjection?.fromPointToLatLng(new google.maps.Point(((coord.x + 1) * tileWidth) / scale, (coord.y * tileHeight) / scale))
  const sw = mapProjection?.fromPointToLatLng(new google.maps.Point((coord.x * tileWidth) / scale, ((coord.y + 1) * tileHeight) / scale))

  if (!ne || !sw) {
    return []
  }

  return [sw.lng(), sw.lat(), ne.lng(), ne.lat()]
}

export const useMapLayerForGoogleMaps = (
  map: Ref<google.maps.Map | undefined>,
  mapIndex: number,
  layer: string,
  duoPPKey?: string,
): SamMapLayer => {
  const enabled = useLocalStorage(`map-layer-${layer}${duoPPKey ? `-${duoPPKey}` : ""}`, false)

  const legendUrl = ref(generateLayerLegendUrl(layer, duoPPKey))

  const updateLayer = debounce(() => {
    if (enabled.value) {
      const overlayOptions = generateLayerOptions(map.value?.getProjection(), layer, duoPPKey)
      const overlay = new google.maps.ImageMapType(overlayOptions)
      map.value?.overlayMapTypes.setAt(mapIndex, overlay)
    } else {
      map.value?.overlayMapTypes.setAt(mapIndex, null)
    }
  }, 400)

  watch(enabled, updateLayer, { immediate: true })

  return {
    enabled,
    mapIndex: ref(mapIndex),
    layer: ref(layer),
    legendUrl: legendUrl,
  }
}

export const defaultCoords: LatLngAlt = { lat: 52.100916, lng: 5.646269 }

export const mapLightStyle = [
  {
    featureType: "poi",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.business",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
]

export const mapDarkStyle = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#242f3e",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#746855",
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#242f3e",
      },
    ],
  },
  {
    featureType: "administrative.locality",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#d59563",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#d59563",
      },
    ],
  },
  {
    featureType: "poi.business",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#263c3f",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#6b9a76",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color: "#38414e",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#212a37",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9ca5b3",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#746855",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#1f2835",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#f3d19c",
      },
    ],
  },
  {
    featureType: "transit",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [
      {
        color: "#2f3948",
      },
    ],
  },
  {
    featureType: "transit.station",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#d59563",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#17263c",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#515c6d",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#17263c",
      },
    ],
  },
]
